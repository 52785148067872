import * as React from "react"

import Container from "../components/Container/Container"
import Seo from "../components/Seo"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

const Privacy = () => {
  return (
    <>
      <Container>
        <Seo title="Bukithub | Privacy Policy" />

        <Header />

        <h1 className="mt-4 fs-2">Privacy Policy for Bukithub</h1>
        <p>
          At Bukithub, accessible from https://www.bukithub.com/, one of our
          main priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Bukithub and how we use it.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>

        <h3 className="mt-4 fs-4">Log Files</h3>
        <p>
          Bukithub follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </p>

        <h3 className="mt-4 fs-4">Cookies and Web Beacons</h3>
        <p>
          Like any other website, Bukithub uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </p>

        <h3 className="mt-4 fs-4">Third Party Privacy Policies</h3>
        <p>
          Bukithub's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. You can choose to disable cookies through your individual
          browser options.
        </p>

        <h3 className="mt-4 fs-4">Children's Information</h3>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          Bukithub does not knowingly collect any Personal Identifiable
          Information from underage children. If you think that your child
          provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>

        <h3 className="mt-4 fs-4">Consent</h3>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>

        <h3 className="mt-4 fs-4">Governing Law</h3>
        <p>This Privacy Policy is governed by Indonesian Law.</p>

        <h3 className="mt-4 fs-4">Contact Us</h3>
        <p className="mb-5">
          If you would like to access or correct any Personal Data which you
          have provided to us, submit a complaint, or have any queries about
          your Personal Data, please contact us at info@bukithub.com.
        </p>
      </Container>

      <Footer />
    </>
  )
}

export default Privacy
